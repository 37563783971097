import React, { createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TextField,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Editicon from "../../Assets/EditIcon";
import { Grid, Typography } from "@material-ui/core";
/* import { Query } from "react-apollo"; */
/* import { ALERTRULE } from '../../Graphql/Queries'; */
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
/* import Loader from "../Common/Loder/Fullloder"; */
import { capitalizeFirstLetter } from "../Common/Common";
// import Popper from '@material-ui/core/Popper';
import { MenuItem } from "@material-ui/core";
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import Grow from '@material-ui/core/Grow';
// import SortDown from '../../Assets/sortDown';
// import { ALERTRULE } from '../../Graphql/Queries'

const headers = [
  "Alert Rule Code",
  "Reference ID",
  "HashTags",
  "Description",
  "Email Subject",
  "SMS Body",
  "Push Title",
  "IsActive",
  "Created At",
  "Actions",
];

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${200}px)`,
      marginLeft: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
    height: `calc(100vh - ${140}px)`,
    overflowX: "auto",
    // marginLeft: "200px"
  },
  table: {
    minWidth: 650,
  },
  EditIcon: {
    width: 20,
    height: 20,
    fill: theme.palette.text.icon,
  },
  SortIcons: {
    height: 10,
    width: 10,
    fill: theme.palette.text.icon,
  },
  methodology: {
    padding: "10px 50px 10px 14px  !important",
  },
  textsort: {
    padding: "10px 22px 10px 14px  !important",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "initial",
  },
  typoContent: {
    fontSize: "0.95rem",
  },
  typoContentRed: {
    fontSize: "0.95rem",
    color: "red",
  },
  typoContentGreen: {
    fontSize: "0.95rem",
    color: "green",
  },
  MenuBox: {
    boxShadow:
      " 0px 2px 1px -1px rgb(255, 255, 255), 0px 1px 1px 0px rgb(255, 255, 255), 0px 1px 3px 0px #9E9E9E",
  },
  TableHeader: {
    backgroundColor: theme.palette.background.tableHeader,
  },
  TableBody: {
    backgroundColor: theme.palette.background.lightBackground,
  },
  rowspage: {
    alignItems: "center",
    display: "flex",
    paddingLeft: "12px",
  },
  subheader1: {
    width: "100%",
    position: "fixed",
  },
  paperelevete1: {
    padding: "10px 16px 16px 16px",
  },
  paperelevete2: {
    padding: "64px 16px 16px 16px",
  },
  fabProgress: {
    // color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: theme.zIndex.drawer,
  },
});
/* const alertRules = []; */
class AlertRuleTable extends React.Component {
  textInput = createRef();
  constructor(props) {
    super(props);
    this.state = {
      category: "True",
      rowperpage: 10,
      page: 0,
      totalmessage: 0,
      offset: 0,
      opendropdown: false,
      alertRuleId: "",
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleChange = (event, name) => {
    this.setState({
      category: event.target.value,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
      offset: newPage * this.state.rowperpage,
    });
    this.props.load_data({
      offset: this.state.page,
      limit: this.state.rowperpage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowperpage: parseInt(event.target.value, 10),
      page: 0,
      offset: 0,
    });
    this.props.load_data({
      offset: this.state.page,
      limit: this.state.rowperpage,
    });
  };
  handleClose = (event) => {
    if (
      this.textInput.current &&
      this.textInput.current.contains(event.target)
    ) {
      return;
    }
    this.setState({ opendropdown: false });
  };
  handleToggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  handleClickValue = (value) => {
    this.setState({ alertRuleId: value });
  };

  focusTextInput = () => this.textInput.current.focus();

  render() {
    const { classes } = this.props;
    return (
      <Grid>
        {this.state.rowperpage === 10 ? (
          ""
        ) : (
          <Grid item style={{ width: "100%", paddingBottom: "10px" }}>
            <Paper className={classes.subheader1}>
              {/* <Typography variant="subtitle1" >
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={this.state.totalmessage}
                                rowsPerPage={this.state.rowperpage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            /></Typography> */}
              <Grid container>
                <Grid item className={classes.rowspage}>
                  <Typography>Row per page:</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    select
                    inputProps={{
                      maxlength: 15,
                    }} // eslint-disable-next-line
                    inputProps={{
                      className: classes.textsort,
                    }}
                    margin="dense"
                    className={classes.margin}
                    InputProps={{ disableUnderline: true }}
                    name="MsgTypeId"
                    value={this.state.rowperpage}
                    onChange={this.handleChangeRowsPerPage}
                  >
                    <MenuItem
                      style={{ padding: 10, cursor: "pointer" }}
                      key={1}
                      value={10}
                    >
                      10
                    </MenuItem>
                    <MenuItem
                      style={{ padding: 10, cursor: "pointer" }}
                      key={2}
                      value={20}
                    >
                      20
                    </MenuItem>
                    <MenuItem
                      style={{ padding: 10, cursor: "pointer" }}
                      key={3}
                      value={50}
                    >
                      50
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        <Grid
          item
          className={
            this.state.rowperpage === 10
              ? classes.paperelevete1
              : classes.paperelevete2
          }
        >
          <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.TableHeader}>
                <TableRow>
                  {headers.map((item, i) => (
                    <TableCell key={i} align="center">
                      <Typography
                        className={classes.heading}
                        variant="subtitle1"
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {this.props.loading && (
                    <TableRow>
                      <TableCell colSpan={headers.length} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!this.props.loading && this.props.alert_data.length <= 0 && (
                    <TableRow>
                      <TableCell colSpan={headers.length} align="center">
                        <Typography>No Data Found!</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!this.props.loading &&
                    this.props.alert_data.length > 0 &&
                    this.props.alert_data.map((row, index) => (
                      <TableRow
                        key={index}
                        className={
                          (index + 1) % 2 === 0 ? classes.TableBody : ""
                        }
                      >
                        <TableCell component="th" scope="row" align="center">
                          <Typography className={classes.typoContent}>
                            {row.alert_rule_code}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.typoContent}>
                            {row.reference_id}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {Boolean(row?.hashtag) &&
                            row?.hashtag
                              .split(",")
                              .map((item, i) => (
                                <Chip
                                  key={i}
                                  label={item}
                                  color="primary"
                                  style={{ margin: "3px" }}
                                />
                              ))}
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.typoContent}>
                            {row.description}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.typoContent}>
                            {" "}
                            {capitalizeFirstLetter(row.email_subject)}
                          </Typography>{" "}
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.typoContent}>
                            {capitalizeFirstLetter(row.sms_body)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.typoContent}>
                            {capitalizeFirstLetter(row.push_title)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.typoContent}>
                            {Boolean(row.isActive) ? (
                              <span className={classes.typoContentGreen}>
                                True
                              </span>
                            ) : (
                              <span className={classes.typoContentRed}>
                                False
                              </span>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.typoContent}>
                            {moment(row.updatedAt).format("MMM Do YYYY")}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              this.props.handleOpenModel(
                                "Edit",
                                row.alert_rule_code
                              );
                            }}
                          >
                            <Editicon className={classes.EditIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              </TableBody>
            </Table>
            <Grid>
              <Typography variant="subtitle1">
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={this.state.totalmessage}
                  rowsPerPage={this.state.rowperpage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles, { withTheme: true })(AlertRuleTable);
