import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ChipInput from "material-ui-chip-input";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogContent,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  /* Toolbar, */
  Divider,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import NetworkCall from "../../NetworkCall";
import { AlertContext } from "../../Contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  clipBoard: {
    position: "relative",
    left: theme.spacing(39),
  },
  grow: {
    flexGrow: 1,
  },
  /* fieldsBody: {
    //background: "#f3f3f3",
    // margin: "10px 15px 17px 8px",
    //marginBottom: "10px",
    //marginTop: "10px",
    //borderRadius: "10px",
    //padding: "10px 15px 10px 15px",
  }, */
  TextfieldIndex: {
    fontSize: "0.65rem",
    color: "#636869",
  },
  buttonAlign: {
    width: "100%",
    textTransform: "none",
  },
}));

function ConfigModal(props) {
  var [state, setState] = React.useState({
    pushServerKey: "",
    alertKey: "",
    error: "",
  });

  var toast = React.useContext(AlertContext);

  var load_data = async () => {
    try {
      var profile = await NetworkCall("/users/get", "post", {}, null, true);
      var alertConfig = await NetworkCall(
        "/configuration/get",
        "post",
        { profileId: window.sessionStorage.getItem("profileId") },
        null,
        true
      );
      setState({
        ...state,
        pushServerKey: alertConfig?.data?.pushServerKey ?? "",
        alertKey: profile.data.alertKey,
      });
    } catch (error) {
      toast.setSnack({
        open: true,
        severity: "error",
        msg: "Some error occured, Please try again!",
      });
    }
  };

  React.useEffect(() => {
    load_data();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  var upsertConfig = () => {
    if (state.pushServerKey.length === 0) {
      state["error"] = "Push Server Key could not be empty!";
      setState({
        ...state,
      });
      return;
    }
    state["error"] = "";
    setState({
      ...state,
    });
    NetworkCall(
      "/configuration/upsert",
      "post",
      {
        profileId: window.sessionStorage.getItem("profileId"),
        pushServerKey: state.pushServerKey,
      },
      null,
      true
    )
      .then((response) => {
        toast.setSnack({
          open: true,
          severity: "success",
          msg: response.data.message,
        });
        props.handleConfigClose();
      })
      .catch((err) => {
        //console.log(err)
        toast.setSnack({
          open: true,
          severity: "error",
          msg: "Some error occured, Please try again!",
        });
      });
  };
  var addEmailCC = (tag) => {
    let cc = emailRegex.test(tag);
    if (cc) {
      setState({
        ...state,
        email_CC: [...state.email_CC, `${tag}`],
      });
      return true;
    } else {
      snack.setSnack({
        open: true,
        severity: "error",
        msg: " Please enter a valid email!",
      });
      return false;
    }
  };
  var removeEmailCC = (tag) => {
    let email_CCs = state.email_CC;
    email_CCs.splice(state.email_CC.indexOf(tag));
    setState({
      ...state,
      email_CC: email_CCs,
    });
  };
  var addEmailBCC = (tag) => {
    let bcc = emailRegex.test(tag);
    if (bcc) {
      setState({
        ...state,
        email_BCC: [...state.email_BCC, `${tag}`],
      });
      return true;
    } else {
      snack.setSnack({
        open: true,
        severity: "error",
        msg: " Please enter a valid email!",
      });
      return false;
    }
  };
  var removeEmailBCC = (tag) => {
    let email_BCCs = state.email_BCC;
    email_BCCs.splice(state.email_BCC.indexOf(tag));
    setState({
      ...state,
      email_BCC: email_BCCs,
    });
  };
  const emailRegex = /\S+@\S+\.\S+/;
  const snack = React.useContext(AlertContext);

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="sm"
        aria-labelledby="configuration-modal"
        open={props.openConfig}
        fullWidth
      >
        <DialogTitle
          disableTypography
          id="configuration-modal"
          className={classes.root}
        >
          <Typography variant="h6">Configuration</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.handleConfigClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Alert Key</Typography>
              <Button
                color="primary"
                className={classes.clipBoard}
                disableRipple
                onClick={() => {
                  navigator.clipboard.writeText(state.alertKey);
                  toast.setSnack({
                    open: true,
                    msg: "Key copied to clipboard!",
                  });
                }}
              >
                Copy to clipboard
              </Button>
              <TextField
                aria-readonly
                id="alertKey"
                variant="outlined"
                multiline
                rowsMax={5}
                fullWidth
                value={state.alertKey}
                name="alertKey"
                margin="dense"
              />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Typography variant="h4">Push</Typography>
              <Typography style={{ fontSize: "1rem" }}>
                FCM Server key
              </Typography>
              <TextField
                id="pushServerKey"
                variant="outlined"
                fullWidth
                value={state.pushServerKey}
                required
                placeholder=""
                name="pushServerKey"
                onChange={(event) => {
                  setState({ ...state, pushServerKey: event.target.value });
                }}
                error={state.error ? true : false}
                helperText={state.error}
                margin="dense"
              />
              <ChipInput
                margin="dense"
                label="Email CC"
                fullWidth
                fullWidthInput
                newChipKeys={["Enter", "Tab", " "]}
                variant="outlined"
                value={state.email_CC}
                onBeforeAdd={(chip) => addEmailCC(chip)}
                onDelete={(chip) => removeEmailCC(chip)}
              />

              <ChipInput
                margin="dense"
                label="Email BCC"
                fullWidth
                fullWidthInput
                newChipKeys={["Enter", "Tab", " "]}
                variant="outlined"
                value={state.email_BCC}
                onBeforeAdd={(chip) => addEmailBCC(chip)}
                onDelete={(chip) => removeEmailBCC(chip)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.buttonInside}
            type="button"
            onClick={() => upsertConfig()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfigModal;
